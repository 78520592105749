
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --navbar-length: 10vh;
  --nav-link-size: 10vh;
  --main-content-length: calc(100vw - var(--navbar-length));
  --light-blue: rgba(0, 255, 255, 1);
  --hamburger-color: rgba(233, 233, 233, 1);
  --navbar-border-blue: rgba(0, 191, 255, 1);
  --theme-icon-color: rgba(255, 250, 160, 1);
  --button-color: #2A6C9F;
  --color-invalid: rgb(255, 0, 0);
  --b: 0.15em;   /* Border length */
  --d: 1em;   /* Cube depth */
  --_s: calc(var(--d) + var(--b));
}

.dark-mode {
  --body-bg: rgba(25, 32, 42, 1);
  --navbar-gradient-in: rgba(40, 40, 40, 1);
  --navbar-gradient-out: rgba(20, 20, 20, 1);
  --navbar-gray: rgba(169, 169, 169, 1);
  --navbar-text-color: rgba(245, 245, 245, 1);
  --header-letter-out-gradient: rgba(239, 249, 251, 1);
  --header-letter-in-gradient: rgba(234, 249, 249,1);
  --title-gradient-in: rgba(255, 240, 100, 1);
  --title-gradient-out: rgba(255, 180, 120, 1);
  --job-title-color: rgba(102, 188, 239, 1);
  --text-shadow: 0 0 0.3em currentColor;
  --main-text-color: rgba(233, 233, 233, 1);
  --box-background-color: rgba(42, 108, 159, 1);
  --box-background:
          conic-gradient(
                  at left var(--d) bottom var(--d),
                  #3333 90deg,
                  rgb(0 0 0 / 0.7) 0 225deg,
                  rgb(0 0 0 / 0.5) 0) border-box,
          conic-gradient(
                  at left var(--_s) bottom var(--_s),
                  #0000 90deg,
                  var(--box-background-color) 0)
          0 100% / calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  --reverse-box-background:
          conic-gradient(
                  from -90deg at right var(--d) bottom var(--d),
                  #3333 90deg,
                  rgb(0 0 0 / 0.4) 0 225deg,
                  rgb(0 0 0 / 0.6) 0) border-box,
          conic-gradient(
                  at right var(--_s) bottom var(--_s),
                  rgba(42, 108, 159, 1) 270deg,
                  #0000 0)
          100% 100% / calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  --project-tile-background: rgba(25, 32, 42, 0.1);
  --project-header-color: rgba(255, 220, 120, 1);
  --project-tech-stack-color: rgba(0, 255, 255, 1);
  --project-img-opacity: 0.4;
  --contact-form-background: #2F3036;
  --rotateX: 0deg;
  --rotateY: 0deg;
}

.light-mode {
  --body-bg: rgba(240, 245, 250, 1);
  --navbar-gradient-in: rgba(55, 55, 55, 1);
  --navbar-gradient-out: rgba(40, 40, 40, 1);
  --navbar-gray: rgba(211, 211, 211, 1);
  --navbar-text-color: rgba(245, 245, 245, 1);
  --header-letter-out-gradient: rgba(33, 33, 33, 1);
  --header-letter-in-gradient: rgba(77, 77, 77, 1);
  --title-gradient-in: rgba(255, 180, 120, 1);
  --title-gradient-out: rgba(199, 0, 57);
  --job-title-color: rgba(22, 110, 160, 1);
  --text-shadow: 0 0 0.3em currentColor;
  --main-text-color: rgba(33, 33, 33, 1);
  --box-background:
          conic-gradient(
                  at left var(--d) bottom var(--d),
                  #EEEE 90deg,
                  rgb(255 255 255 / 0.3) 0 225deg,
                  rgb(255 255 255 / 0.6) 0) border-box,
          conic-gradient(
                  at left var(--_s) bottom var(--_s),
                  #0000 90deg,
                  rgba(42, 108, 159, 1) 0)
          0 100% / calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  --reverse-box-background:
          conic-gradient(
                  from -90deg at right var(--d) bottom var(--d),
                  #DDDD 90deg,
                  rgb(255 255 255 / 0.5) 0 225deg,
                  rgb(255 255 255 / 0.2) 0) border-box,
          conic-gradient(
                  at right var(--_s) bottom var(--_s),
                  rgba(42, 108, 159, 1) 270deg,
                  #0000 0)
          100% 100% / calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  --project-tile-background: rgba(255, 255, 255, 0.4);
  --project-header-color: rgba(35, 42, 52, 1);
  --project-tech-stack-color: rgba(2, 70, 120, 1);
  --project-img-opacity: 0.6;
  --contact-form-background: #F5F5F5;
  --rotateX: 0deg;
  --rotateY: 0deg;
}

/* Apply the following properties to all id/class names containing "grid-" */
[id*="grid-"],
[class*="grid-"] {
  display: grid;
  gap: 1rem 3rem;
}

body {
  width: 100vw;
  min-height: 100vh;
  font-family: Google Sans, Arial, serif;
  background-color: var(--body-bg);
  transition: 0.2s background-color ease-in-out;
  overflow-x: hidden;
}

nav,
.nav {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  place-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  font-size: 1.6vh;
  background-color: var(--navbar-gradient-in);
  border: 1px solid var(--navbar-gradient-in);
  min-height: 100vh;
}

.nav-link {
  position: relative;
  font-weight: 600;
  place-items: center;
  justify-content: center;
  text-align: center;
  background-image:
          radial-gradient(
                  circle 50vh at 50% 50%,
                  var(--navbar-gradient-in) 0%,
                  var(--navbar-gradient-out) 100% );
  color: var(--navbar-gray);
  border: 1px solid var(--navbar-gradient-in);
  text-decoration: none;
  cursor: pointer;
  width: var(--nav-link-size);
  height: var(--nav-link-size);
  transition: 0.2s opacity ease-in-out;
}

.switch-theme:hover {
  border: 1px solid var(--navbar-gray);
}

.layer-1,
.layer-2,
#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition:
          0.3s opacity ease-in-out,
          0.3s color ease-in-out;
  user-select: none;
}

.layer-1 {
  opacity: 1;
}

.layer-2 {
  opacity: 0;
}

.nav-link:hover > .layer-1 {
  opacity: 0.05;
}

.nav-link:hover > .layer-2 {
  opacity: 1;
  color: var(--navbar-text-color);
}

.external-nav-link-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
}

.external-nav-link {
  border: none;
  background-image: none;
  width: calc(var(--nav-link-size) / 4) !important;
  height: var(--nav-link-size) !important;
  padding: 5px;
  transform: scale(1.3);
  margin: 3px;
}

.external-nav-link:hover {
  border: none !important;
}

.external-nav-link:hover > .layer-1 {
  opacity: 1;
}

.external-nav-link:hover > .layer-1 {
  color: var(--light-blue);
}

.theme-icon {
  color: var(--theme-icon-color);
}

.theme-icon + .layer-2 {
  font-size: 1.6vh;
  color: var(--theme-icon-color);
}

#logo-icon-wrapper {
  transform: scale(0.6);
  max-width: 100%;
  max-height: 100%;
  transition: 0.2s opacity ease-in-out;
}

.nav-link:hover > #logo-icon-wrapper {
  opacity: 0.05;
}

.active {
  color: var(--light-blue);
  border: 1px solid var(--navbar-border-blue);
  pointer-events: none;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--nav-link-size);
  background: var(--navbar-gradient-in);
}

button {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--nav-link-size);
  height: var(--nav-link-size);
  background: transparent;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  position: absolute;
  top: 100px;
  width: 230px;
}

li {
  list-style: none;
  display: flex;
  align-items: center;
}

main {
  display: inline-block;
  place-items: center;
  color: var(--main-text-color);
  font-size: 1.3rem;
  width: var(--main-content-length);
  padding: 0;
  margin: 0 0 0 var(--navbar-length);
  overflow-x: hidden;
}

section {
  display: grid;
  place-items: center;
  text-align: center;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

section::-webkit-scrollbar {
  display: none;
}

.vertical-margin {
  margin-top: 5rem;
}

.fill-viewport {
  min-height: 100vh !important;
}

.individual-page {
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  padding: 0;
}

.content-slide {
  position: absolute;
}

.content-slide ~ .delayed {
  opacity: 0;
}

.grid-split {
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-rows: auto;
}

#logo-wrapper {
  width: 450px;
  height: 435px;
  transform:
          scale(1)
          rotate(15deg)
          skew(-15deg);
  animation: 1s tranform-logo-wrapper ease-in-out forwards;
  animation-delay: 3s !important;
}

.delayed:nth-child(1) {
  bottom: 35px;
  left: 80px;
  opacity: 0;
  animation: 1.25s tetris-fade-in ease-in-out forwards;
  animation-delay: 0.25s !important;
}

.delayed:nth-child(2) {
  bottom: 35px;
  left: 290px;
  opacity: 0;
  animation: 1.35s tetris-fade-in ease-in-out forwards;
  animation-delay: 0.5s !important;
}

.delayed:nth-child(3) {
  bottom: 175px;
  left: 80px;
  opacity: 0;
  animation: 1.45s tetris-fade-in ease-in-out forwards !important;
  animation-delay: 0.75s !important;
}

.delayed:nth-child(4) {
  bottom: 175px;
  left: 10px;
  opacity: 0;
  animation: 1.5s tetris-fade-in ease-in-out forwards !important;
  animation-delay: 1s !important;
}

.delayed:nth-child(5) {
  bottom: 315px;
  left: 80px;
  opacity: 0;
  animation: 1.75s tetris-fade-in ease-in-out forwards;
  animation-delay: 1.25s;
}

.inner-tetris-tile {
  animation: 1s normalize-tile ease-in-out forwards;
  animation-delay: 3.25s;
}

.inner-tetris-shadow {
  animation: 1s normalize-shadow ease-in-out forwards;
  animation-delay: 3.25s;
}

.animated-tetris-block {
  animation: 2.5s spin-tetris-block ease-out;
}

.animated-memoji {
  animation: 1s fade-in-wave ease-out forwards;
}

.letter {
  display: inline-block;
}

.animated-letter {
  animation: 1s bounce-up ease-in-out forwards;
}

h1,
h2,
h3 {
  display: block;
  flex-flow: column wrap;
  white-space: pre;
  flex-wrap: wrap;
  font-size: 3.75rem;
  text-align: center;
  font-family: "Source Code Pro", "Roboto Mono", monospace;
  margin-bottom: 3rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.75rem;
}

.line {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.letter {
  display: inline-block;
  background-image:
          radial-gradient(
                  circle farthest-corner at 10% 20%,
                  var(--header-letter-in-gradient) 0.1%,
                  var(--header-letter-out-gradient) 90.1% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-gradient {
  background-size: 200%;
  background-image:
          linear-gradient(
                  to right,
                  var(--title-gradient-out),
                  var(--title-gradient-in) );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.job-title {
  position: relative;
  min-height: 150px;
  color: var(--job-title-color);
  -webkit-background-clip: text;
  white-space: pre-line;
  line-height: 2.5rem;
  font-family: "Roboto Mono", "Source Code Pro", monospace;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}

.job-title,
.button {
  opacity: 0;
  animation: 0.5s fade-in ease-in-out forwards;
  animation-delay: 0.5s;
}

.button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: "Sora", "Google Sans", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  user-select: none;
  color: var(--job-title-color);
  background: var(--body-bg);
  border: 1px solid var(--button-color);
  cursor: pointer;
  padding: 1rem 2.5rem;
  margin: 0 1rem 1rem 1rem;
  transition:
          color 250ms ease-in-out,
          background-color 250ms ease-in-out;
}

.button:hover,
.button:focus {
  color: var(--body-bg);
  background-color: var(--job-title-color);
}

.glow-effect {
  --glow-line-length: 25px;
  --glow-line-color: #FFFFFFFF;
  --glow-line-thickness: 1px;
  --glow-blur-color: var(--job-title-color);
  --glow-blur-size: 6px;
  --glow-offset: 0px;
  --stroke-offset: -1000000px;
  --animation-length: 50000s;
  /* do not change - used for calculations */
  --container-offset: 50px;
  position: relative;
}

.glow-container {
  pointer-events: none;
  position: absolute;
  inset: calc(var(--container-offset) / -2);
  width: calc(100% + var(--container-offset) + var(--glow-offset));
  height: calc(100% + var(--container-offset) + var(--glow-offset));
  opacity: 0;
}

.glow-blur,
.glow-line {
  width: calc(100% - var(--container-offset));
  height: calc(100% - var(--container-offset));
  transform: translate(
          calc((var(--container-offset) / 2) + (var(--glow-offset) / -2)),
          calc((var(--container-offset) / 2) + (var(--glow-offset) / -2)));
  fill: transparent;
  stroke: black;
  stroke-width: 5px;
  stroke-dasharray:
          var(--glow-line-length)
          calc(50px - var(--glow-line-length));
}

.glow-line {
  stroke: var(--glow-line-color);
  stroke-width: var(--glow-line-thickness);
}

.glow-blur {
  filter: blur(var(--glow-blur-size));
  stroke: var(--glow-blur-color);
  stroke-width: var(--glow-blur-size);
}

.glow-effect:is(:hover, :focus) :is(.glow-line, .glow-blur) {
  stroke-dashoffset: var(--stroke-offset);
  transition:
          opacity var(--animation-length) linear,
          stroke-dashoffset var(--animation-length) linear;
}

.glow-effect:hover .glow-container {
  animation: glow-visibility-infinite var(--animation-length) linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes glow-visibility-infinite {
  0.0005% { opacity: 1 }
  0.0005%, 100% { opacity: 1 }
}

.glow-effect[data-glow-navbar-item=true] {
  --glow-line-color: var(--navbar-border-blue);
  --glow-blur-color: var(--navbar-border-blue);
  --glow-blur-size: 1px;
}

.glow-effect[data-glow-intro-button=true] {
  border-radius: 1rem;
}
.glow-effect[data-glow-intro-button=true] .glow-blur,
.glow-effect[data-glow-intro-button=true] .glow-line {
  rx: 1rem;
}

.glow-effect[data-glow-intro-button=true] {
  --stroke-offset: -65px;
  --animation-length: 1000ms;
}

.glow-effect:is(:hover, :focus) :is(.glow-effect[data-glow-intro-button=true]) {
  stroke-dashoffset: var(--stroke-offset);
  transition:
          opacity var(--animation-length) ease-in,
          stroke-dashoffset var(--animation-length) ease-in;
}

.glow-effect[data-glow-intro-button=true]:hover .glow-container {
  animation: glow-visibility var(--animation-length) ease-in;
  animation-fill-mode: none;
  animation-iteration-count: 1;
}

@keyframes glow-visibility {
  50%, 100% { opacity: 0 }
  50%, 67% { opacity: 1 }
}

.glow-effect[data-glow-full-width=true] {
  --glow-line-length: 50px;
}

.glow-effect[data-glow-offset=true] {
  --glow-offset: 10px;
}

.glow-effect[data-glow-project-tile=true] {
  --glow-line-color: transparent;
  --glow-blur-color: #FFFFFF;
}

#logo {
  opacity: 0.9;
  transform:
          translate(-50%, -50%)
          scale(0.5);
}

.nav-link:hover > #logo {
  opacity: 0.1;
}

.text-sphere {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
}

.tagcloud {
  display: inline-block;
  font-family: "Source Code Pro", "Roboto Mono", monospace;
  font-weight: 600;
  color: var(--project-tech-stack-color);
  user-select: none;
}

.tagcloud--item:hover {
  color: var(--navbar-border-blue);
}

#profile-photo-wrapper {
  position: relative;
  transform-style: preserve-3d;
  transform:
          rotateX(var(--rotateX))
          rotateY(var(--rotateY));
  transition: 0.1s transform linear;
}

#profile-photo-wrapper::before,
#profile-photo-wrapper::after {
  content: "";
  position: absolute;
  opacity: 0;
  border-radius: 20px;
  animation: 0.5s fade-in ease-in-out forwards;
  animation-delay: 0.5s;
}

#profile-photo-wrapper::before {
  inset: -0.5em;
  filter: blur(15px);
  background: #000000;
  border-radius: 50%;
  transform: translateZ(-49px);
  opacity: 0.3 !important;
}

#profile-photo-wrapper::after {
  inset: -2em;
  background-image:
          linear-gradient( 135deg, orange, indianred );
  transform: translateZ(-50px);
}

.memoji-wrapper {
  border-radius: 50%;
  overflow: hidden;
  transition: 0.5s transform ease-in-out;
  background-image:
          linear-gradient( 135deg, yellow, blue );
  animation: 1s fade-in-wave ease-in-out forwards;
  animation-delay: 0.5s;
}

.memoji {
  place-self: center;
  transform: scale(1.05);
}

.text-section {
  font-size: 1.05rem;
  font-weight: 600;
  font-family: "Inter", "Google Sans", sans-serif;
  text-align: left;
  line-height: 1.5rem;
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background: var(--box-background);
  clip-path:
          polygon(
                  0% var(--d),
                  var(--d) 0%,
                  100% 0%,
                  100% calc(100% - var(--d)),
                  calc(100% - var(--d)) 100%,
                  0% 100% );
  padding: 2em 2em 3em 3em;
  margin: 0;
}

.box-background {
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background: var(--box-background);
  clip-path:
          polygon(
                  0% var(--d),
                  var(--d) 0%,
                  100% 0%,
                  100% calc(100% - var(--d)),
                  calc(100% - var(--d)) 100%,
                  0% 100% );
}

.reverse-box-background {
  border-width: var(--b) var(--_s) var(--_s) var(--b);
  background: var(--reverse-box-background);
  clip-path:
          polygon(
                  0% 0%,
                  calc(100% - var(--d)) 0%,
                  100% var(--d),
                  100% 100%,
                  var(--d) 100%,
                  0 calc(100% - var(--d)) ) !important;
}

#portfolio-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  gap: 2rem;
  flex-flow: column wrap;
  place-items: center;
  width: var(--main-content-length);
}

.project-tile {
  display: flex;
  position: relative;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  white-space: initial;
  overflow-wrap: break-word;
  font-family: "Source Code Pro", "Fira Code", monospace;
  width: 400px;
  height: 300px;
  padding: 1em 2em 2em 1em;
  margin: auto;
  overflow: hidden;
}

.project-tile > h3,
.project-tile > p,
.project-tile > a,
.tile-tech-stack > p {
  color: var(--project-header-color);
  background-color: var(--project-tile-background);
  border-radius: 10px;
  padding: 0.5em;
  transition:
          0.3s transform ease-in-out,
          0.3s opacity ease-in-out;
}

.project-tile > h3 {
  text-align: center;
  white-space: initial;
  overflow-wrap: break-word;
  font-size: 20px;
  margin: 0;
  z-index: 2;
}

.project-tile > p {
  font-weight: 600;
  text-align: left;
  font-size: 1rem;
  background-color: transparent;
  z-index: 2;
}

.tile-tech-stack {
  display: flex;
  flex-flow: row-reverse wrap-reverse;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  width: 100%;
  z-index: 2;
}

.tile-tech-stack > p {
  font-size: 12px;
  font-weight: 600;
  color: var(--project-tech-stack-color);
  border: 1px solid var(--project-tech-stack-color);
  padding: 0.5em;
  z-index: 2;
}

.project-tile > a {
  position: absolute;
  top: var(--b);
  right: var(--_s);
  width: calc(100% - (var(--b) * 2 + var(--d)));
  height: calc(100% - (var(--b) * 2 + var(--d)));
  opacity: var(--project-img-opacity);
  z-index: 1;
}

.project-tile > a > img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.project-tile > a > span {
  position: absolute;
  left: 50%;
  margin-left: -63px;
  top: 50%;
  margin-top: -62px;
  text-decoration: none;
  color: var(--project-header-color);
  background: var(--body-bg);
  text-align: center;
  overflow-wrap: break-word;
  font-family: "Sora", "Source Code Pro", sans-serif;
  font-size: 16px;
  border-radius: 50%;
  opacity: 0;
  width: 125px;
  height: 125px;
  padding: 43px 10px;
  z-index: 3;
  transform: scale(0.5);
  transition:
          0.3s transform ease-in-out,
          0.3s opacity ease-in-out,
          0.3s color ease-in-out,
          0.3s background-color ease-in-out;
}

.project-tile:hover > h3 {
  transform: translateY(-15px);
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.project-tile:hover > p {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.project-tile:hover > .tile-tech-stack > p {
  transform: translateY(15px);
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.project-tile:hover > a {
  opacity: 1;
  z-index: 2;
}

.project-tile:hover > a > span {
  opacity: 0.95;
  transform: scale(1);
}

.project-tile:hover > a:hover > span {
  color: var(--body-bg);
  background: var(--project-header-color);
}

.disabled-link {
  color: var(--project-header-color);
  background: var(--body-bg);
  pointer-events: none;
  cursor: default;
}

.code {
  color: var(--job-title-color);
  font-weight: 800;
  font-family: "Source Code Pro", "Fira Code", monospace;
  white-space: pre;
}

.text-link {
  text-decoration: none;
  color: var(--project-tech-stack-color);
  transition: 0.2s opacity ease-in-out;
}

.text-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.padded-left-area {
  padding: 3rem 0 3rem 3rem;
}

.padded-area {
  padding: 3rem;
}

.grid-form {
  grid-template-rows: 40px 40px 3fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px 10px;
  padding: 1em 2em 2em 1.25rem;
}

.span-1 {
  grid-column: span 1;
}

.span-2 {
  grid-column: span 2;
}

form > input, form > textarea {
  grid-auto-rows: max-content;
  font-family: "Inter", "Google Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--job-title-color);
  background: var(--contact-form-background);
  border: 2px solid var(--contact-form-background);
  outline: none;
  width: 100%;
  height: 3.5rem;
  padding: 1rem;
  transition: 0.2s border ease-in-out;
}

form > input[type=text]:hover,
form > input[type=email]:hover,
form > textarea:hover {
  border: 2px solid var(--navbar-gray);
}

form > input[type=text]:focus,
form > input[type=email]:focus,
form > textarea:focus {
  border: 2px solid var(--job-title-color);
}

form > .set-text-box-height {
  min-height: 200px;
  resize: vertical;
}

.invalid-field {
  border-bottom: 2px solid var(--color-invalid) !important;
}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.contact-map {
  width: 100%;
  height: 100%;
}

.Toastify__toast {
  background: var(--body-bg) !important;
  color: var(--main-text-color) !important;
  font-size: 1rem;
  border-radius: 1px;
}

.Toastify {
  z-index: 1000;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-wave {
  0%, 90%, 100% { transform: scale(1.05) rotate(0deg); }
  30%, 60% { transform: scale(1.15) rotate(5deg); }
  45%, 75% { transform: scale(1.15) rotate(-5deg); }
}

@keyframes tetris-fade-in {
  0% {
    opacity: 0;
    transform: translate(0%, -150%);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fade-out {
  from { opacity: 1; }
  to {
    opacity: 0;
    transform: translate(0, 5%);
  }
}

@keyframes transform-logo-wrapper {
  100% {
    transform:
            scale(1)
            rotate(15deg)
            skew(-15deg);
  }
}

@keyframes normalize-tile {
  100% { fill: #00FFFF; }
}

@keyframes normalize-shadow {
  100% { fill: #00CED1; }
}

@keyframes spin-tetris-block {
  10% { transform: rotate(-27deg); }
  20% { transform: rotate(-30deg); }
  25% { transform: rotate(-30deg); }
  70%, 72% { transform: rotate(747deg); }
  80%, 82% { transform: rotate(710deg); }
  90%, 92% { transform: rotate(725deg); }
  100% { transform: rotate(720deg); }
}

@keyframes bounce-up {
  10% {
    opacity: 1;
    filter: hue-rotate(36deg);
    transform:
            scale(1.1, 0.8)
            translateY(0);
  }
  30% {
    opacity: 1;
    filter: hue-rotate(108deg);
    transform:
            scale(0.9, 1.1)
            translateY(-10px);
  }
  50% {
    opacity: 1;
    filter: hue-rotate(180deg);
    transform:
            scale(1.05, 0.95)
            translateY(0);
  }
  59% {
    opacity: 1;
    filter: hue-rotate(213deg);
    transform:
            scale(1, 1)
            translateY(-5px);
  }
  69% {
    opacity: 1;
    filter: hue-rotate(244deg);
    transform:
            scale(1, 1)
            translateY(0);
  }
  100% {
    opacity: 1;
    filter: hue-rotate(360deg);
    transform:
            scale(1, 1)
            translateY(0);
  }
}

@keyframes background-pan {
  from { background-position: 0 center; }
  to { background-position: -200% center; }
}

@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }

  20%, 80% { transform: translate3d(2px, 0, 0); }

  30%, 50%, 70% { transform: translate3d(-3px, 0, 0); }

  40%, 60% { transform: translate3d(4px, 0, 0); }
}

.nav-ul {
  display: none;
  list-style: none;
}

@media (max-width: 768px) {
  :root {
    --navbar-length: 0;
    --main-content-length: 100vw;
  }

  nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: none;
    display: flex;
    place-items: flex-start;
    justify-content: flex-start;
    flex-flow: row nowrap;
    background-color: var(--body-bg);
    border: none;
    width: auto;
    height: auto;
  }

  .nav-ul {
    display: flex;
    opacity: 0.95;
    z-index: 999;
  }

  .full-media-only {
    display: none;
    pointer-events: none;
  }

  .menu-toggle-paths {
    transition: 0.2s stroke ease-in-out;
  }

  #menu-toggle:hover .menu-toggle-paths {
    stroke: var(--light-blue);
  }

  main {
    padding: 60px 0 0 0;
  }

  section {
    overflow-x: hidden;
  }

  #logo-wrapper {
    transform:
            scale(0.75)
            rotate(15deg)
            skew(-15deg)
            translateX(-10%);
    max-width: 100vw;
  }

  .text-sphere {
    transform: scale(0.75);
  }

  .fill-viewport {
    padding: 0;
  }

  .padded-area,
  .padded-left-area {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .grid-split {
    grid-template-columns: 1fr;
    min-width: 100vw;
    max-width: 100vw;
  }

  [id*="tetris-block"] {
    transform: scale(0.8);
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 2rem;
  }

  .delayed:nth-child(1) {
    bottom: 60px;
    left: 85px;
  }

  .delayed:nth-child(2) {
    bottom: 46px;
    left: 267px;
  }

  .delayed:nth-child(3) {
    bottom: 172px;
    left: 85px;
  }

  .delayed:nth-child(4) {
    bottom: 158px;
    left: 43px;
  }

  .delayed:nth-child(5) {
    bottom: 284px;
    left: 85px;
  }

  .text-sphere {
    max-width: 100vw;
    transform: scale(0.6);
  }

  #portfolio-section {
    width: 100vw;
    grid-template-columns: 1fr;
  }

  .project-tile {
    width: 100vw;
    min-height: 80vw;
  }

  .tile-tech-stack > p {
    font-size: 0.7rem;
  }

  .padded-area,
  .padded-left-area {
    padding: 0;
    margin: 0;
  }

  .vertical-margin {
    margin-top: 5rem;
  }
}
